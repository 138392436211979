document.querySelectorAll('.sib-form input').forEach(function (v, k) {
    v.classList.add('js-load-recaptcha');
});

var recpatchaScript = document.getElementById('script-recaptcha');

function loadRecaptchaScript() {
    var script = document.createElement('script');
    // script.onload = function () {
    //     //do stuff with the script
    // };
    script.src = recpatchaScript.getAttribute('data-src');
    document.head.appendChild(script);
}

var scriptLoaded = false;
document.querySelectorAll('.js-load-recaptcha').forEach(function (v, k) {
   v.addEventListener('click', function () {
       if (!scriptLoaded) {
           scriptLoaded = true;
           loadRecaptchaScript();
       }
   }) ;
});